.symbol.symbol-20 > img {
  width: 100%;
  max-width: 20px;
  height: 20px;
}

.navi {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;

  .navi-item {
    padding: 0;
    display: block;
    list-style: none;

    .navi-link {
      display: flex;
      align-items: center;
      padding: 8px;

      .navi-icon {
        line-height: 0;
        flex-shrink: 0;

        &:before {
          line-height: 0;
        }
      }

      .navi-text {
        flex-grow: 1;
        margin-left: 8px;
      }

      .navi-label {
        //line-height: 0;
      }

      .navi-toolbar {
        margin-left: 10px;
      }
    }
  }
}
