$page-bg: #f1f2f9;
$primary: #2365F8;
$primary-active: #2D2D73;
$primary-light: #e9effe;
$primary-inverse: #ffffff;
$success: #75529d;
//$success-hover: #339088;
$success-light: #e9effe;
//$success-inverse: #ffffff;
//$warning: #ffa800;
//$warning-hover: #ee9d01;
//$warning-light: #fff4de;
//$warning-inverse: #ffffff;
//$danger: #e8534f;
//$danger-hover: #cb3129;
//$danger-light: #F9DDDC;
//$danger-inverse: #ffffff;
$info: #6e6db2;
//$info-hover: #585996;
//$info-light: #F3F3F7;
//$info-inverse: #ffffff;
$gray-100: #F5F5F5;
$gray-200: #F5F5F5;
$gray-300: #E0E0E0;
$gray-400: #D6D6D6;
$gray-500: #B8B8B8;
$gray-600: #7A7A7A;
$gray-700: #5C5C5C;
$gray-800: #333333;
$gray-900: #1B1B1B;
