.rpv-core__viewer--dark .rpv-core__inner-page {
  background-color: red;
}

.rpv-default-layout__toolbar {
  background-color: #f5f5f5;
  color: #5C5C5C;
  font-size: 1.1rem;
  font-weight: 500;
  border-top-right-radius: 0.475rem;
  border-top-left-radius: 0.475rem;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 24px;
}

.rpv-core__inner-pages {
  margin-top: 20px;
}

.rpv-default-layout__container {
  border: 1px solid #f5f5f5 !important;
  border-radius: 0.475rem;
}

.rpv-toolbar__item {
  margin: 4px;
}